@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
/*
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
*/
@font-face {
  font-family: 'summa-icons';
  src: url('../fonts/summa-icons/summa-icons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: auto
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/inter/inter-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

body {
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1920px;
  background: #F2F2F1  0% 0% no-repeat padding-box !important;
  opacity: 1;
}
.App {
  text-align: center;
}
.blockquote-footer::before {
  content: "" !important;
}
.title-ppal {
 /* Layout Properties */
/* UI Properties */
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-nunito);
color: var(--unnamed-color-727272);
text-align: left;
font: normal normal 300 15px/20px Nunito !important;
letter-spacing: 3px;
color: #727272;
text-transform: uppercase;
opacity: 1;
margin-bottom: 30px;
}
.title-desc {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #8d8d8d;
  /*   opacity: 70%; 
  margin-bottom: 1.5rem;*/
}

.title-boleta {
/* Layout Properties */
/* UI Properties */
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-1400) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-nunito);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-727272);
text-align: left;
font: normal normal 1400 15px/17px Nunito !important;
letter-spacing: 0px;
color: #727272;
opacity: 1;
margin-bottom: 3px;
}
.ingresa-rut {
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
}
.ingresa-boleta,
.desc-rut,
.desc-monto {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-727272);
  text-align: left;
  font: italic normal 300 17px/23px Nunito !important;
  letter-spacing: 0px;
  color: #727272;
  opacity: 1;
  border: none;
  outline: none; 
  padding: 10px; 
  box-shadow: none; 
  width: 100% !important;
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F2F2F1;
}

.App-link {
  color: #61dafb;
}
/* /////////////////////////////////////////////////////////////////////////// */
.container-fullscreen {
  display: flex;
  flex-direction: row; /* Disposición en fila para pantallas grandes */
  width: 100vw;
  height: 100vh;
}

.img-mob {
  background-image: url(../img/baby/C-ISLAND_2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50vw; /* 50% del ancho de la pantalla en pantallas grandes */
  height: 100vh;
}

.content-side {
  width: 30vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .container-fullscreen {
      flex-direction: column;
      gap: 10px; /* Ajusta el espacio entre los elementos en pantallas pequeñas */
      height: auto;
  }

  .img-mob,
  .content-side {
      width: 100vw;
      height: 50vh;
  }
}

/* Media query para pantallas intermedias (tablets y monitores de baja resolución) */
@media (min-width: 768px) and (max-width: 1024px) {
  .container-fullscreen {
      flex-direction: column;
      gap: 10px;
      height: auto;
  }

  .img-mob,
  .content-side {
      width: 100vw;
      height: 50vh;
  }
}

/* ///////////////////////////////////////////////////////////////////////////////////// */
.img-logo-mob {
  background-image: url(../img/LogoMob.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
}
.btn-color {
  /* UI Properties */
  border: 0.3px solid #607380 !important; 
  border-radius: 11px !important;
  opacity: 1 !important;
  font: normal normal 500 12px/37px 'Nunito', sans-serif !important;
  letter-spacing: 2.16px !important;
  color: #607380 !important;
  text-transform: uppercase !important;
  background-color: #f0f0f0 !important; 
  display: block;
  width: 60%; 
  padding: 0px 60px !important; 
  margin-top: 20px;
  text-align: center; /* Alinea el texto al centro */
}

/* Efecto hover */
.btn-color:hover {
  color: #fff !important; /* Cambia el color del texto a blanco */
  background-color: #A3979B !important; /* Cambia el fondo al color especificado */
  border: none !important; /* Elimina el borde */
}
.footerStyle {
  background-color: #4c4c4c ;
  border-radius: 0% ;
}

.float-label-control {
  position: relative;
  margin-bottom: 0.5em;
}

.float-label-control input,
.float-label-control textarea,
.float-label-control label {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.float-label-control textarea:focus {
  padding-bottom: 0px;
}
.float-label-control input,
.float-label-control textarea {
  width: 344px;
  height: 37px;
  padding: 0em 0em 0px 0em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 9px;
  border-color: #FFFFFF;
  opacity: 0,1;
}
@media only screen and (min-width: 321px) {
  .title-ppal {
    font-size: 27px;
  }
  .title-desc {
    font-size: 12px;
  }
}
@media only screen and (min-width: 375px) {
  .img-mob {
    background-size: cover;
  }
}
@media only screen and (min-width: 411px) {
}

@media only screen and (min-width: 568px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-mob {
    height: 330px;
  }
}

@media only screen and (min-width: 800px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1023px) {
  .img-logo-mob {
    background-image: url(../img/LogoDesk.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 24px;
  }

  .img-mob {
    background-image: url(../img/baby/C-ISLAND_22x.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30rem;
  }
  .title-ppal {
    text-align: left;
    font-size: 20px;
  }
  .title-desc {
    text-align: left;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
    width: 67%;
  }
}
@media only screen and (min-width: 1200px) {
  .title-ppal {
    font-size: 26px;
    font: normal normal medium 12px/37px Nunito !important;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
    /* width: 40%; */
  }
}
@media only screen and (min-width: 1366px) {
  .img-mob {
    height: 40rem;
  }
  .float-label-control input,
  .float-label-control textarea {
    display: block;
   /* width: 40%; */
  }
}
@media only screen and (min-width: 1440px) {
  .img-mob {
    height: 40rem;
  }
}
@media only screen and (min-width: 1560px) {
  .img-mob {
    height: 68rem;
  }
}
@media only screen and (min-width: 1600px) {
  .img-mob {
    height: 57rem;
  }
}
@media only screen and (min-width: 1920px) {
  .img-mob {
    height: 68rem;
  }
}

div.mage-error {
  color: #de1414;
  font-weight: 300;
  line-height: 16px;
  font-size: 12px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  letter-spacing: normal;
}
.page-section {
  padding: 0px !important;
}




